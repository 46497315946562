// import 'intl-tel-input/build/css/intlTelInput.min.css';

import "./utils";

import "./swiper";
import "./popup";
import "./accordeon";
import "./servicesTab";
import "./diagnosticAccordeon";
import "./mask";
import "./formMask";
import "./formSubmit";
import "./blog";

import "../css/main.scss";
