import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const reviewsSwiper = new Swiper(".reviews-swiper", {
  autoHeight: true,
  slidesPerView: "auto",
  spaceBetween: 11,
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 11,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
});

function initializeSwiper() {
  let swiper;

  function initSwiper() {
    if (swiper) {
      swiper.destroy(true, true);
    }

    swiper = new Swiper(".assistance-swiper", {
      slidesPerView: "auto",
      spaceBetween: 20,
      autoHeight: true,
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 4,
        },
      },
      navigation: {
        nextEl: window.innerWidth <= 992 ? ".mobile-next" : ".desktop-next",
        prevEl: window.innerWidth <= 992 ? ".mobile-prev" : ".desktop-prev",
      },
    });
  }

  // Ініціалізація Swiper при завантаженні сторінки
  document.addEventListener("DOMContentLoaded", initSwiper);

  // Оновлення Swiper при зміні розміру вікна
  window.addEventListener("resize", initSwiper);
}

// Виклик функції для ініціалізації Swiper
initializeSwiper();
