function servicesTab() {
  const servicesItems = document.querySelectorAll(".services-item");
  let activeIndex = 0;
  servicesItems[activeIndex].classList.add("active");

  servicesItems.forEach((item, index) => {
    item.addEventListener("click", () => {
      servicesItems[activeIndex].classList.remove("active");
      item.classList.add("active");
      activeIndex = index;
    });
  });
}

document.addEventListener("DOMContentLoaded", servicesTab);
