function accordeon() {
  const btns = document.querySelectorAll(".diagnostic-accordion-item-title");
  const texts = document.querySelectorAll(".diagnostic-accordion-item-text");
  let activeIndex = 0;
  btns[activeIndex].classList.add("active");
  texts[activeIndex].classList.add("active");

  btns.forEach((btn, index) => {
    btn.addEventListener("click", () => {
      btns[activeIndex].classList.remove("active");
      texts[activeIndex].classList.remove("active");
      btn.classList.add("active");
      texts[index].classList.add("active");
      activeIndex = index;
    });
  });
}
document.addEventListener("DOMContentLoaded", accordeon);
