// const token =
//   "EABBQaexYIQMBOyk6YvuN8PPoUtEqeLETGSewD3Th4ZAciqQdRtHcj6E5SF8vskSTOZAFxHKv3YE4dr2POQazz2zktGSMaiszhZChfGAaASLZCwFiwU4xtKakan0vwpfmMb4M4rJMs2ZAgo9zgh6qwyuBjAVOQXEZBZB0q7AUtoks9hbbBFZBrn1gviOW";
// const id = "17841462437832873";

// fetch(
//   `https://graph.facebook.com/v20.0/${id}/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=${token}`
// )
//   .then((response) => response.json())
//   .then((data) => {
//     console.log(data);
//   });
